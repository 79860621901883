define("fx/templates/budgets/budget/budget-periods/budget-period/dynamic/rates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jw3hgkXU",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"dynamic.rates.title\"],null]],[13],[1,\"\\n\\n\"],[10,2],[12],[1,[28,[35,0],[\"coming-soon\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"dynamic.rates.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"t\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/dynamic/rates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});