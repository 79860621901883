define("fx/templates/budgets/budget/budget-periods/budget-period/dynamic/financial-flows/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+GylcmE",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"dynamic.financial-flows.title\"],null]],[13],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"dynamicFinancialFlowsByCurrency\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@financialFlows\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@save\"],[[30,0,[\"dynamicFinancialFlowsUpdate\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"dynamic.financial-flows.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"dynamicFinancialFlows\"],false,[\"t\",\"each\",\"-track-array\",\"data/financial-flows-table\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/dynamic/financial-flows/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});