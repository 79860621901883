define("fx/templates/budgets/budget/budget-periods/budget-period/dynamic/unit-sold-quantities/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v654VGGc",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"dynamic.unit-sold-quantities.title\"],null]],[13],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"unitSoldQuantitiesByProduct\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@unitSoldQuantities\",\"@errors\"],[[30,1],[30,0,[\"errors\"]]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@save\"],[[30,0,[\"unitSoldQuantitiesUpdate\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@target\"],[\"sidebar\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"dynamic.unit-sold-quantities.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"unitSoldQuantities\"],false,[\"t\",\"each\",\"-track-array\",\"dynamic/unit-sold-quantities-table\",\"flow-bar\",\"portal\",\"sidebar\"]]",
    "moduleName": "fx/templates/budgets/budget/budget-periods/budget-period/dynamic/unit-sold-quantities/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});